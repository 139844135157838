<template>
  <!-- Layout Header -->
  <a-layout-header class="layout-header" :style="{ height: device.mobile ? '50px' : '74px' }">
    <a-row>
      <a-col :span="24">
        <div class="letwecare-breadcrumb">
          <a-breadcrumb
            v-if="!device.mobile"
            class="__breadcrumb"
            :routes="breadcrumbRoutes"
          >
            <template #itemRender="{ routes, route }">
              <span v-if="routes.indexOf(route) === routes.length - 1">
                {{ route.breadcrumbName }}
              </span>
              <router-link v-else :to="route.path">
                {{ route.breadcrumbName }}
              </router-link>
            </template>
          </a-breadcrumb>

          <a-page-header
            class="__page-header"
            style="height: 63px; padding-left: 0;"
            :title="`${courseData.name}：訊息推播`"
            @back="backToCoursePage"
          >
            <template #itemRender="{ route, params, routes, paths }">
              <span v-if="routes.indexOf(route) === routes.length - 1">{{route.breadcrumbName}}</span>
              <router-link v-else :to="paths.join('/')">{{route.breadcrumbName}}</router-link>
            </template>
          </a-page-header>
        </div>
      </a-col>
    </a-row>
  </a-layout-header>
  <!-- Layout Header -->

  <!-- Layout Body -->
  <a-layout class="app-layout push-messages-layout">
    <a-row :gutter="12">
      <!-- Body: editor -->
      <a-col :lg="16" :xs="24">
        <a-card class="layout-body">
          <div class="body-item">
            <a-row>
              <!-- 訊息設定 -->
              <a-col :lg="16" :xs="24">
                <div class="item-title">訊息設定</div>

                <div class="item-content">
                  <a-row class="mb-4" :gutter="24">
                    <a-col :lg="4" :xs="10" align="right">
                      <div class="mt-1" :style="{ color: '#757575' }">傳送對象</div>
                    </a-col>
                    <a-col :lg="20" :xs="14">
                      <a-radio-group
                        v-model:value="settings.to"
                      >
                        <a-radio :style="radioStyle" value="all">所有班級學員</a-radio>
                        <a-radio :style="radioStyle" disabled value="specific">篩選班級學員</a-radio>
                      </a-radio-group>
                    </a-col>
                  </a-row>

                  <a-row :gutter="24">
                    <a-col :lg="4" :xs="10" align="right">
                      <div class="mt-1" :style="{ color: '#757575' }">傳送時間</div>
                    </a-col>
                    <a-col :lg="20" :xs="14">
                      <a-radio-group
                        v-model:value="settings.time"
                      >
                        <a-radio :style="radioStyle" value="now">立即傳送</a-radio>
                        <a-radio :style="radioStyle" disabled value="cron">設定傳送時間</a-radio>
                      </a-radio-group>
                    </a-col>
                  </a-row>
                </div>
              </a-col>
              <!-- 訊息設定 -->

              <!-- 人數統計 -->
              <a-col :lg="8" :xs="24" align="center">
                <div :style="{ marginTop: '30px' }">
                  <div class="mb-4" :style="{ color: '#424242' }">預計推播人數</div>
                  <a-progress
                    type="circle"
                    :percent="bindedUserCount/usersData.length*100"
                  >
                    <template #format="percent">
                      <span>{{ bindedUserCount }} 人</span>
                    </template>
                  </a-progress>
                </div>
              </a-col>
              <!-- 人數統計 -->
            </a-row>
          </div>

          <div class="body-item">
            <div class="item-title">訊息內容</div>

            <div class="item-content">
              <div
                v-for="(item, itemIdx) in formState"
                class="content-editor"
              >
                <div class="editor-toolbar">
                  <a-row>
                    <a-col :span="12">
                      <a-button
                        @click="changeFormat('text', itemIdx)"
                        :class="{ 'btn--active': item.type === 'text' }"
                        type="text" size="large"
                      >
                        <template #icon>
                          <message-outlined />
                        </template>
                      </a-button>
                      <a-button
                        @click="changeFormat('image', itemIdx)"
                        :class="{ 'btn--active': item.type === 'image' }"
                        type="text" size="large"
                      >
                        <template #icon>
                          <file-image-outlined />
                        </template>
                      </a-button>
                      <a-button
                        @click="changeFormat('sticker', itemIdx)"
                        :class="{ 'btn--active': item.type === 'sticker' }"
                        type="text" size="large"
                      >
                        <template #icon>
                          <smile-outlined />
                        </template>
                      </a-button>
                    </a-col>
                    
                    <a-col
                      v-if="formState.length>1"
                      :span="12"
                      align="right"
                    >
                      <a-button
                        v-if="itemIdx != 0"
                        @click="moveUp(itemIdx)"
                        type="text" size="large"
                      >
                        <template #icon>
                          <up-outlined />
                        </template>
                      </a-button>
                      <a-button
                        v-if="itemIdx != formState.length-1"
                        @click="moveDown(itemIdx)"
                        type="text" size="large"
                      >
                        <template #icon>
                          <down-outlined />
                        </template>
                      </a-button>
                      <a-button type="text" size="large" @click="deleteOneItem(itemIdx)">
                        <template #icon>
                          <close-outlined />
                        </template>
                      </a-button>
                    </a-col>
                  </a-row>
                </div>

                <div class="editor-body">
                  <template v-if="item.type === 'text'">
                    <a-textarea
                      class="mb-4"
                      v-model:value="item.text"
                      placeholder="請輸入文字訊息"
                      :auto-size="{ minRows: 3 }"
                      maxlength="500"
                      showCount
                    />
                  </template>

                  <template v-if="item.type === 'image'">
                    <a-upload
                      v-model:file-list="item.imageFileList"
                      action="/api/v1/file"
                      list-type="picture"
                      accept=".png, .jpg, .jpeg"
                      :before-upload="beforeImageUpload"
                      @change="handleImageChange"
                    >
                      <a-button>
                        <upload-outlined />
                        上傳圖片
                      </a-button>
                    </a-upload>
                  </template>

                  <template v-if="item.type === 'sticker'">
                    <template v-if="item.package_id && item.sticker_id">
                      <img
                        :src="require(`/src/assets/sticker-images/${item.package_id}/${item.sticker_id}@2x.png`)"
                        alt="sticker-img"
                        :style="{ height: '160px' }"
                      />
                    </template>

                    <a-button @click="openPickStickerModal(itemIdx)">選擇貼圖</a-button>
                  </template>
                </div>
              </div>

              <a-button @click="insertOneItem"><plus-outlined /> 新增</a-button>
            </div>
          </div>

          <div class="body-actions" align="center">
            <a-button
              @click="submitMessages"
              class="px-8" type="primary" size="large"
            >
              傳送訊息
            </a-button>
          </div>
        </a-card>
      </a-col>
      <!-- Body: editor -->

      <!-- Sider: History Messages -->
      <a-col :lg="8" :xs="24">
        <a-card class="layout-sider">
          <div class="sider-title">推播紀錄</div>

          <div class="sider-body">
            <template v-if="multicastRecordsData.length">
              <div
                v-for="record in multicastRecordsData"
                class="record-card"
              >
                <div class="card-header">
                  <a-row>
                    <a-col :span="12">
                      所有學員
                    </a-col>
                    <a-col :span="12" align="right">
                      {{ DateUtil.formatDatetime(record.created_datetime) }} 
                    </a-col>
                  </a-row>
                </div>
                <div class="card-body">
                  <div
                    v-for="message in record.messages"
                    class="body-message"
                  >
                    <template v-if="message.type === 'text'">
                      <div>{{ message.text }}</div>
                    </template>
                    <template v-if="message.type === 'image'">
                      <img
                        :src="message.original_content_url"
                        :style="{ height: '100px' }"
                        alt="message-image"
                      >
                    </template>
                    <template v-if="message.type === 'sticker'">
                      <div>
                        <img
                          :src="require(`/src/assets/sticker-images/${message.package_id}/${message.sticker_id}@2x.png`)"
                          :style="{ height: '100px' }"
                          alt="sticker-img"
                        />
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <a-empty
                class="mt-10"
                :image="simpleImage"
                description="沒有推播紀錄"
              />
            </template>
          </div>
        </a-card>
      </a-col>
      <!-- Sider: History Messages -->
    </a-row>
  </a-layout>
  <!-- Layout Body -->

  <PickStickerModal
    :visible="pickStickerModal.visible"
    @pick="handleStickerPick"
    @close="pickStickerModal.visible = false"
  />
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { message, Empty } from 'ant-design-vue'
import { PlusOutlined, UpOutlined, DownOutlined, CloseOutlined, MessageOutlined, FileImageOutlined, SmileOutlined } from '@ant-design/icons-vue'
import { createDeviceDetector } from 'next-vue-device-detector'
import PickStickerModal from './PickStickerModal.vue'
import { useAuthStore } from '@/store/auth'
import api from '@/apis'
import DateUtil from '@/utils/DateUtil'

export default defineComponent({
  components: {
    PlusOutlined,
    UpOutlined,
    DownOutlined,
    CloseOutlined,
    MessageOutlined,
    FileImageOutlined,
    SmileOutlined,
    PickStickerModal
  },
  setup() {
    const route = useRoute();
    const device = createDeviceDetector();

    /* Store */
    const authStore = useAuthStore();

    /* Loading */
    const loading = ref(false);
    const submitting = ref(false);

    /* Data */
    const courseData = ref({});
    const usersData = ref([]);
    const bindedUserCount = ref(0);
    const multicastRecordsData = ref([]);

    /* Form State */
    const initialState = [{
      type: 'text',
      text: ''
    }]
    const formState = ref([]);
    formState.value = initialState.map(item => Object.assign({}, item));
    function resetForm() {
      formState.value = initialState.map(item => Object.assign({}, item));
      console.log(initialState);
    }

    /* Image Uploader */
    const handleImageChange = async info => {
      if (info.file.status === 'uploading') {
        return
      }

      if (info.file.status === 'removed') {
        try {
          await deleteFile(info.file.response.result.file_src);
        } catch (error) {
          console.log('Delete image image error. Error message: ' + error);
        }
      }

      if (info.file.status === 'done') {
        // 刪除前一個上傳的 image
        // if (imageFileList.value.length > 1) {
        //   const firstElement = imageFileList.value.shift();
        //   try {
        //     await deleteFile(firstElement.response.result.file_src);
        //   } catch (error) {
        //     console.log('Delete image image error. Error message: ' + error);
        //   }
        // }
      }

      if (info.file.status === 'error') {
        message.error('upload error');
      }
    }

    const beforeImageUpload = file => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('請選擇 jpg 或 png 的圖片格式');
      }

      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('圖片大小不可以超過 2MB');
      }

      return isJpgOrPng && isLt2M;
    }

    /* Api */
    const getCourseData = (async () => {
      const response = await api.v1.course.findOne(route.params.course_id);
      courseData.value = response.data.result;
      console.log(courseData.value);
    });

    const getMembers = (async () => {
      const response = await api.v1.course.findMembers('user', {
        course_id: route.params.course_id
      });
      usersData.value = response.data.result;
      bindedUserCount.value = response.data.result.filter(item => item.line_id).length;
      console.log(usersData.value);
      console.log(bindedUserCount.value);
    });

    const getMulticastRecordsData = (async () => {
      const response = await api.v1.multicastRecord.list({
        course_id: route.params.course_id
      });
      multicastRecordsData.value = response.data.result;
      console.log(multicastRecordsData.value);
    });

    const deleteFile = (async (code) => {
      const codeSplitList = code.split('/');
      const key = codeSplitList[codeSplitList.length-1];
      await api.v1.file.deleteOne({ key: key });
    });

    /* Routes breadcrumb */
    const breadcrumbRoutes = ref([
      {
        path: '/app/courses',
        breadcrumbName: '班級管理'
      }
    ]);

    /* Mounted */
    onMounted(async () => {
      loading.value = true;

      await getCourseData();
      await getMembers();
      await getMulticastRecordsData();

      // set routes breadcrumb
      breadcrumbRoutes.value.push({
        path: `/app/course/${ route.params.course_id }`,
        breadcrumbName: courseData.value.name
      });
      breadcrumbRoutes.value.push({
        path: `/app/course/${ route.params.course_id }/push-messages`,
        breadcrumbName: '訊息推播'
      });

      loading.value = false;
    });

    /* Style */
    const radioStyle = ref({
      display: 'flex',
      height: '30px',
      lineHeight: '30px',
    });

    return {
      device,

      /* Store */
      authStore,

      /* Routes breadcrumb */
      breadcrumbRoutes,

      /* Loading */
      loading,
      submitting,

      /* Data */
      courseData,
      usersData,
      bindedUserCount,
      multicastRecordsData,

      /* Form State */
      formState,
      resetForm,

      /* Image Uploader */
      handleImageChange,
      beforeImageUpload,

      /* Api */
      getMulticastRecordsData,

      /* Antd */
      message,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,

      /* Utils */
      DateUtil,

      /* Style */
      radioStyle
    }
  },
  data() {
    return {
      settings: {
        to: 'all', // all|specific
        time: 'now' // now|cron
      },

      /* Pick Sticker Modal */
      pickStickerModal: {
        visible: false,
        itemIdx: null
      }
    }
  },
  methods: {
    /* Router */
    backToCoursePage() {
      this.$router.push({
        name: 'AppCoursePage',
        params: {
          course_id: this.$route.params.course_id
        }
      });
    },

    /* Editor Functions */
    insertOneItem() {
      this.formState.push({
        type: 'text',
        text: ''
      });
    },
    moveUp(idx) {
      [this.formState[idx-1], this.formState[idx]] = [this.formState[idx], this.formState[idx-1]]
    },
    moveDown(idx) {
      [this.formState[idx+1], this.formState[idx]] = [this.formState[idx], this.formState[idx+1]]
    },
    deleteOneItem(idx) {
      this.formState.splice(idx, 1);
    },
    changeFormat(type, itemIdx) {
      if (type === 'text') {
        this.formState[itemIdx] = {
          type: 'text',
          text: ''
        }
      }
      else if (type === 'image') {
        this.formState[itemIdx] = {
          type: 'image',
          original_content_url: '',
          preview_image_url: '',
          imageFileList: [] // for a-upload
        }
      }
      else if (type === 'sticker') {
        this.formState[itemIdx] = {
          type: 'sticker',
          package_id: '',
          sticker_id: ''
        }
      }
    },

    /* Pick Sticker Modal */
    openPickStickerModal(itemIdx) {
      this.pickStickerModal.itemIdx = itemIdx;
      this.pickStickerModal.visible = true;
    },
    handleStickerPick(packageId, stickerId) {
      this.formState[this.pickStickerModal.itemIdx].package_id = packageId;
      this.formState[this.pickStickerModal.itemIdx].sticker_id = stickerId;
      this.pickStickerModal.visible = false;
    },
    
    /* Api */
    _checkRequired() {
      let err = '';

      for (const idx in this.formState) {
        const item = this.formState[idx];
        if (item.type === 'text') {
          if (item.text === '') {
            err = '請輸入訊息內容';
            break
          }
        }
        else if (item.type === 'image') {
          if (!item.imageFileList.length) {
            err = '請上傳圖片';
            break
          }

          const file_uri = item.imageFileList[0].response.result.file_src;
          item.original_content_url = file_uri;
          item.preview_image_url = file_uri;
        }
        else if (item.type === 'sticker') {
          if (!item.package_id || !item.sticker_id) {
            err = '請選擇貼圖';
            break
          }
        }
      }

      return err
    },
    async submitMessages() {
      const err = this._checkRequired();
      if (err) {
        this.message.error(err);
      }

      try {
        await api.v1.line.courseMulticast(
          this.$route.params.course_id,
          {
            messages: this.formState,
            settings: this.settings
          }
        )
        this.getMulticastRecordsData();
        this.resetForm();
        this.message.success('推播訊息成功');
      } catch (error) {
        console.log(error);
        this.message.error('推播訊息發生錯誤，請稍後再試，或聯絡管理人員');
      }
    },
  }
})
</script>

<style lang="scss">
.push-messages-layout {
  padding: 12px;
  overflow: auto;
  overflow-x: hidden;

  .layout-body {
    padding: 12px;
    margin-bottom: 12px;

    .ant-card-body {
      padding: 8px !important;
    }

    /* md */
    @media screen and (min-width: 970px) {
      margin-bottom: 0;

      .ant-card-body {
        padding: 24px !important;
      }
    }

    .body-item {
      margin-bottom: 44px;

      .item-title {
        font-size: 1.25rem;
        letter-spacing: 0.0125rem;
        margin-bottom: 16px;
      }

      .item-content {
        .content-editor {
          border: 1px solid #BDBDBD;
          border-radius: 4px;
          margin-bottom: 20px;
          
          .editor-toolbar {
            background: #f9f9f9;
            border-bottom: 1px solid #BDBDBD;
            padding: 8px 12px;

            /* md */
            @media screen and (min-width: 970px) {
              padding: 8px 24px;
            }

            .btn--active {
              background: #e8e8e8;
            }
          }

          .editor-body {
            padding: 12px;

            /* md */
            @media screen and (min-width: 970px) {
              padding: 24px;
            }
          }
        }
      }
    }
  }

  .layout-sider {
    .sider-title {
      font-size: 1.25rem;
      letter-spacing: 0.0125rem;
      margin-bottom: 16px;
    }

    .sider-body {
      .record-card {
        border: 1px solid #BDBDBD;
        border-radius: 4px;
        margin-bottom: 16px;
        
        .card-header {
          color: #424242;
          font-weight: 450;
          background: #f9f9f9;
          border-bottom: 1px solid #BDBDBD;
          padding: 8px 16px;
        }

        .card-body {
          padding: 8px;

          .body-message {
            background: #f9f9f9;
            padding: 12px;
            margin: 8px;
          }
        }
      }
    }
  }
}
</style>